<!-- 我的学分 -->
<template>
    <div class="credit">
        <div class="warpper">
            <h2 class="title">{{$store.state.user.currentProject.name}}</h2>
        </div>
        <!-- <div class="time flex-align">
            <div class="options">时间:</div>
            <el-date-picker v-model="value1" type="datetimerange" range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期" size='mini' @change="changeTime" value-format='yyyy-MM-dd HH:mm:ss'>
            </el-date-picker>
        </div> -->
        <div class="total">总计：{{total}}</div>
        <div class="box flex-between">
            <div class="tableBox">
                <el-table :data="rankData" style="width: 100%">
                    <el-table-column prop="index" label="序号" width="180">
                    </el-table-column>
                    <el-table-column prop="typeName" label="学分项目" width="180">
                    </el-table-column>
                    <el-table-column prop="score" label="学分" width="180">
                        <!-- <template slot-scope="scope">
                            {{isNaN(scope.row.score)?'--':scope.row.total}}
                        </template> -->
                    </el-table-column>
                </el-table>
            </div>
            <div class="rankBox">
                <div class="top flex-align">
                    <img src="../../assets/images/home/cup.png" alt="">
                    积分排行榜
                </div>
                <div class="rank">
                    <tempalte v-if="rankList.length>0">
                        <div class="rankItem flex-align" v-for="(item,index) in rankList" :key="index">
                            <div class="num">
                                <img v-if="index==0" src="../../assets/images/score/first.png" alt="">
                                <img v-if="index==1" src="../../assets/images/score/second.png" alt="">
                                <img v-if="index==2" src="../../assets/images/score/third.png" alt="">
                                <span v-if="index>2">{{index+1}}</span>
                            </div>
                            <img class="avatar" :src="downloadURL + item.avatar" :onerror="defaultAvatar">
                            <div class="info flex-align-between">
                                <span class="wes-2">{{item.name}}</span>
                                <span>{{item.score}}</span>
                            </div>
                        </div>
                    </tempalte>
                    <el-empty v-else description="暂无排行"></el-empty>
                </div>
            </div>
        </div>
        <div class="myPages flex-align-around">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page.sync="currentPage" :page-sizes="[5, 8, 10]" :page-size="limit"
                layout=" prev, pager, next,sizes" :total="total" background>
            </el-pagination>
        </div>
    </div>
</template>
<script>
    import {
        mapState
    } from "vuex";
    export default {
        data() {
            return {
                value1: '',
                tableData: [{
                        id: 1,
                        projectName: '研修学习',
                        score: 5
                    },
                    {
                        id: 1,
                        projectName: '研修学习',
                        score: 5
                    }, {
                        id: 1,
                        projectName: '研修学习',
                        score: 5
                    },
                    {
                        id: 1,
                        projectName: '研修学习',
                        score: 5
                    }, {
                        id: 1,
                        projectName: '研修学习',
                        score: 5
                    },
                    {
                        id: 1,
                        projectName: '研修学习',
                        score: 5
                    }, {
                        id: 1,
                        projectName: '研修学习',
                        score: 5
                    },
                    {
                        id: 1,
                        projectName: '研修学习',
                        score: 5
                    }, {
                        id: 1,
                        projectName: '研修学习',
                        score: 5
                    },
                    {
                        id: 1,
                        projectName: '研修学习',
                        score: 5
                    }
                ],
                currentPage: 1,
                limit: 10,
                startTime: '',
                endTime: '',
                rankData: [],
                total: 0,
                rankList: []
            }
        },
        computed: {
            ...mapState({
                defaultAvatar: state => state.user.defaultAvatar,
            }),
        },
        methods: {
            handleSizeChange(page) {
                this.page = page;
                this.getRankData()
            },
            handleCurrentChange(page) {
                console.log(page);
                this.page = page;
                this.getRankData()
            },
            changeTime(val) {
                console.log(val)
                this.startTime = val[0];
                this.endTime = val[1];
                this.getRankData()
            },
            // 学分排行记录
            getRankData() {
                let data = {
                    // startTime: this.startTime,
                    // finishTime: this.endTime,
                    page: this.currentPage,
                    limit: this.limit,
                    projectId: this.$store.state.user.currentProject.id
                }
                this.$Api.Score.myScore(data)
                    .then(res => {
                        console.log('学分排行记录:', res);
                        res.data.records.map((item, index) => {
                            item.index = index + 1
                        })
                        this.rankData = res.data.records;
                        this.total = res.data.total
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            // 积分排行榜
            getScoreRank() {
                let data = {
                    page: 1,
                    limit: 50,
                    projectId: this.$store.state.user.currentProject.id
                }
                this.$Api.Score.scoreRank(data)
                    .then(res => {
                        console.log('积分排行榜:', res);
                        this.rankList = res.data.records
                    })
                    .catch(err => {
                        console.log('err:', err)
                    })
            }
        },
        mounted() {
            this.getRankData();
            this.getScoreRank()
        },
    };
</script>
<style lang='less' scoped>
    .credit {
        border-radius: 10px;
        background: #ffffff;

        .warpper {
            padding-left: 9px;
        }

        .title {
            border-bottom: 1px solid #f9f9f9;
            padding-left: 35px;
            line-height: 69px;
            font-size: 22px;
        }

        .time {
            margin: 20px 0;

            .options {
                flex-shrink: 0;
                margin-right: 10px;
                margin-left: 10px;
            }
        }

        .total {
            margin-left: 10px;
        }

        .box {
            margin-top: 10px;
            margin-bottom: 10px;

            .tableBox {
                margin-left: 10px;
                // width: 600px;
                // height: 500px;
                // border: 1px solid #f9f9f9;
            }

            .rankBox {
                margin-right: 10px;
                min-width: 300px;
                // height: 300px;
                border-radius: 10px;
                border: 1px solid #f9f9f9;

                .top {
                    padding: 20px;
                    border-bottom: 1px solid #f9f9f9;

                    img {
                        margin-right: 10px;
                        width: 30px;
                    }
                }

                .rank {
                    padding: 30px;
                    max-height: 400px;
                    overflow-y: auto;

                    &::-webkit-scrollbar {
                        width: 0 !important
                    };

                    & {
                        -ms-overflow-style: none;
                    };

                    & {
                        overflow: -moz-scrollbars-none;
                    };

                    .rankItem {
                        margin-bottom: 29px;
                        font-size: 14px;
                        color: #333333;

                        .num {
                            width: 32px;
                            height: 40px;
                            text-align: center;
                            line-height: 32px;

                            img {
                                width: 100%;
                            }
                        }

                        .avatar {
                            width: 42px;
                            height: 42px;
                            border-radius: 50%;
                            margin: 0 23px;
                        }

                        .info {
                            flex-grow: 1;
                        }
                    }
                    /deep/ .el-empty__image{
                        width: 100px !important;
                    }
                }
            }
        }

        .myPages {
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }
</style>